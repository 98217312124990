import { Toaster } from '@/components/ui/sonner';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
// import { useEffect } from 'react';
import { isRouteErrorResponse, Links, LinksFunction, Meta, MetaFunction, Outlet, Scripts, ScrollRestoration, useRouteError } from 'react-router';
import globalStylesheetUrl from '~/global.css?url';
// import { validateVersion } from '~/lib/versioning';
import { queryClient } from '~/queryClient';
import { isFetchError } from '~/services/utils/error';
import { ErrorPage } from './components/ErrorPage';
import { RobinLoading } from './components/robin/RobinLoading';
import NotFoundPage from './routes/404';
import Maintenance from './routes/maintenance';
import { generateMetaTags } from './services/utils/meta';

export const meta: MetaFunction = () => {
  return generateMetaTags({});
};

export const links: LinksFunction = () => [{ rel: 'icon', href: '/favicon.ico', type: 'image/x-icon' }];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en" suppressHydrationWarning>
      <head suppressHydrationWarning>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link href={globalStylesheetUrl} rel="stylesheet" />
        <script defer data-domain="robinfeed.com" src="https://plausible.io/js/script.file-downloads.hash.outbound-links.pageview-props.tagged-events.js"></script>
        <script dangerouslySetInnerHTML={{ __html: `window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }` }}></script>
        <link rel="icon" type="image/png" href="/favicon/favicon-96x96.png" sizes="96x96" />
        <link rel="icon" type="image/svg+xml" href="/favicon/favicon.svg" />
        <link rel="shortcut icon" href="/favicon/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <meta name="apple-mobile-web-app-title" content="Robin Feed" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <Meta />
        <Links />
      </head>
      <body suppressHydrationWarning>
        <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  Sentry.captureException(error);

  if (isRouteErrorResponse(error) || isFetchError(error)) {
    switch (error.status) {
      case 404:
        return <NotFoundPage />;
      case 503:
        return <Maintenance />;
      default:
        break;
    }
  }
  console.log('ErrorBoundary:', error);
  <ErrorPage error={error} />;
};

export const HydrateFallback = () => {
  return <RobinLoading />;
};

export default function App() {
  // useEffect(() => {
  //   const version = queryClient.getQueryData(['validate-version']);
  //   if (!version)
  //     queryClient.fetchQuery({
  //       queryFn: validateVersion,
  //       queryKey: ['validate-version'],
  //     });
  // }, []);

  return <Outlet />;
}
